import { Inject, Injectable } from '@angular/core';
import { BaseLogger } from '@core/utilities/logger';
import { AuthService } from '@tempton/ngx-msal';
import { Observable } from 'rxjs';
import { envToken, IEnvironment } from '../environment';
import { ILazyLoadScript, IScriptLoaderService, Script, scriptLoaderServiceToken } from '../script-loader';
import { IInitializer } from './initializer.provider';

@Injectable({providedIn: 'root'})
export class Initializer implements IInitializer {
    constructor(
        @Inject(envToken) private env: IEnvironment,
        @Inject(scriptLoaderServiceToken) private scriptLoader: IScriptLoaderService,
        private authService: AuthService,
    ) {
    }

    public init(): Observable<boolean> {
        BaseLogger.consoleEnabled = !this.env.production;

        if (this.env.production) {
            this.loadGoogleTagManager();
        }

        return this.authService.ensureSignedIn$({});
    }

    private loadGoogleTagManager(): Promise<ILazyLoadScript> {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});

        return this.scriptLoader.load(Script.GTM, this.env.gtmKey);
    }
}
