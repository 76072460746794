import { ViewportRuler } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    template: '<router-outlet />',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
    private VIEWPORT_RELOAD_BREAKPOINT = 768;

    private isMobile: boolean = window.innerWidth < this.VIEWPORT_RELOAD_BREAKPOINT;

    constructor(private viewport: ViewportRuler) {
        this.viewport.change().subscribe(() => {
            if (this.viewportShouldBeReloaded()) {
                window.location.reload();
            }
        });
    }

    private viewportShouldBeReloaded(): boolean {
        const {width} = this.viewport.getViewportSize();

        return (this.isMobile && width >= this.VIEWPORT_RELOAD_BREAKPOINT)
            || (!this.isMobile && width < this.VIEWPORT_RELOAD_BREAKPOINT);
    }
}
